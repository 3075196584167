<template>
  <div class="container-fluid">
    <ControlNavbar />
    <div class="header">
      PROFILE
    </div>
    <br />
    VERIFICATION PAGE
  </div>
</template>

<script>
// @ is an alias to /src
import "bootstrap/dist/css/bootstrap.min.css";
import ControlNavbar from "@/components/ControlNavbar";

// import "slick-carousel/slick/slick.css";
// import axios from "axios";
// import firebase from "firebase";
// import Slick from "vue-slick";

export default {
  name: "Verification",
  components: { ControlNavbar },
  data() {
    return {};
  },
  methods: {},
  created: function() {},
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  .header {
    padding: 20px;
    background: #e6e6e6;
    box-shadow: 7px 6px 5px grey;
  }
}
</style>
